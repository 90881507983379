import { Button, Select, Spinner, TextField } from '@cimpress/react-components';
import { useContext, useState } from 'react';
import {
    CardBuilder,
    Content,
    Control,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    type SelectorOption,
} from '../Components';
import { useCancelableEffect } from '../tools';
import { ApiKeyContext } from '../App';
import { getImageFromTextUrls } from '../api';
import { useGenerateImageFromTextParams } from '../Components/GenerateImageFromText/useGenerateImageFromText';

const modelOptions = [
    {
        label: 'Basic',
        value: 'basic',
    },
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Premium',
        value: 'premium',
    },
];

const imageCountOptions = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
];

const contentTypeOptions = [
    { label: 'Photo', value: 'photo' },
    { label: 'Clipart', value: 'clipart' },
];

const aspectRatioOptions = [
    { label: 'Square', value: 'square' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
];

export const GenerateImageFromText = () => {
    const {
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        imageCountSelectedOption,
        contentTypeSelectedOption,
        setPrompt,
        setModelSelectedOption,
        setAspectRatioSelectedOption,
        setImageCountSelectedOption,
        setContentTypeSelectedOption,
    } = useGenerateImageFromTextParams();

    const [resultRow, setResultRow] = useState(PreviewRow());
    const [isLoading, setIsLoading] = useState(false);
    const [callGenerate, setCallGenerate] = useState(false);
    const apiKey = useContext(ApiKeyContext);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (callGenerate && prompt) {
                    setIsLoading(true);

                    try {
                        const resultImageUrls: string[] = await getImageFromTextUrls({
                            prompt: prompt,
                            modelSelectedOption: modelSelectedOption.value,
                            contentTypeSelectedOption: contentTypeSelectedOption.value,
                            aspectRatioSelectedOption: aspectRatioSelectedOption.value,
                            imageCountSelectedOption: imageCountSelectedOption.value,
                            signal,
                            apiKey,
                        });

                        const resultCards = resultImageUrls?.map((imageUrl, i) =>
                            CardBuilder.ofType('imageUrl')
                                .withTitle(`Image ${i + 1}`)
                                .withData({
                                    imageUrl,
                                })
                                .build(),
                        );

                        setResultRow(PreviewRow(...resultCards));
                    } catch (error) {
                        if (signal.aborted) {
                            return;
                        }

                        const resultCard = CardBuilder.ofType('documentUrl')
                            .withTitle('Result Document')
                            .withLoader(async () => {
                                throw error;
                            })
                            .build();
                        setResultRow(PreviewRow(resultCard));
                    }

                    setIsLoading(false);
                    setCallGenerate(false);
                }
            })();
        },
        [
            callGenerate,
            prompt,
            imageCountSelectedOption.value,
            apiKey,
            modelSelectedOption.value,
            aspectRatioSelectedOption.value,
            contentTypeSelectedOption.value,
        ],
    );

    const onModelChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setModelSelectedOption(selected);
    };

    const onContentTypeChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setContentTypeSelectedOption(selected);
    };

    const onAspectRatioChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setAspectRatioSelectedOption(selected);
    };

    const onImageCountChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setImageCountSelectedOption(selected);
    };

    function onClickGenerate() {
        setCallGenerate(true);
    }

    return (
        <Page>
            <Page.Title>Generate Images from Text (Beta)</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Prompt</Control.Title>
                    <TextField label="Prompt" onChange={(e) => setPrompt(e.target.value)} value={prompt ?? ''} />
                </Control>

                <Control>
                    <Control.Title>Model</Control.Title>
                    <Select onChange={onModelChange} value={modelSelectedOption} options={modelOptions} label="Model" />
                </Control>
                <Control>
                    <Control.Title>Content Type</Control.Title>
                    <Select
                        onChange={onContentTypeChange}
                        value={contentTypeSelectedOption}
                        options={contentTypeOptions}
                        label="ContentType"
                    />
                </Control>
                <Control>
                    <Control.Title>AspectRatio</Control.Title>
                    <Select
                        onChange={onAspectRatioChange}
                        value={aspectRatioSelectedOption}
                        options={aspectRatioOptions}
                        label="Aspect Ratio"
                    />
                </Control>

                <Control>
                    <Control.Title>Image Count</Control.Title>
                    <Select
                        onChange={onImageCountChange}
                        value={imageCountSelectedOption}
                        options={imageCountOptions}
                        label="Image Count"
                    />
                </Control>
                <Control>
                    <Button onClick={onClickGenerate}>Generate</Button>
                </Control>
            </Page.Controls>

            <Page.Content>
                {isLoading ? (
                    <Content.Card sticky size="large">
                        <Content.Section>
                            <Spinner />
                        </Content.Section>
                    </Content.Card>
                ) : (
                    <PreviewSection
                        introduction={
                            'This is a page to test the Generate Image From Text endpoint. Fill in the prompt to see generated results.'
                        }
                        showIntro={!prompt}
                        rows={PreviewRows(resultRow)}
                    />
                )}
            </Page.Content>
        </Page>
    );
};

export default GenerateImageFromText;
