import type { CimpressDocument } from '@mcp-artwork/cimdoc-types-v2';
import axios from 'axios';
import base64js from 'base64-js';
import pako from 'pako';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { getNonceQueryParam, getUrl, REQUESTER } from '../tools';
import type { GenerateAssets } from './composeGenerateApi';
import type { ComposeAssets } from './compositeApi';
import type { InspirationGenerateOptions } from './inspirationGenerateApi';
import { uploadJsonDocumentAndGetDocumentUrl } from './uploadJsonDocumentAndGetDocumentUrl';

const host = config.backendServiceUrl;

export const getResizeByDimensionsUrl = (
    documentUrl: string | null,
    width: string,
    height: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
): string | null => {
    if (!documentUrl) {
        return null;
    }

    const url =
        `${host}/api/${version}/adaptation:resize?width=${width}&height=${height}&documentUrl=${encodeURIComponent(
            documentUrl,
        )}&requester=${REQUESTER}${
            minimumFontSize ? '&minimumFontSize=' + minimumFontSize : ''
        }&${getNonceQueryParam()}&apiKey=${apiKey}` +
        (version === 'v3beta' ? `&priority=${encodeURIComponent(priority)}` : '');

    return url;
};

export const getTransferCustomizationUrl = (
    sourceUrl: string | null,
    targetUrl: string | null,
    transferCustomerAssets: boolean,
    apiKey: string,
    useSmartTextTransfer: boolean = false,
): string | null => {
    if (!sourceUrl || !targetUrl) {
        return null;
    }

    const source = encodeURIComponent(getUrl(sourceUrl) ?? '');
    const target = encodeURIComponent(getUrl(targetUrl) ?? '');

    return `${host}/api/v3/preservation:transferCustomizations?sourceDocumentUrl=${source}&targetDocumentUrl=${target}&transferCustomerAssets=${transferCustomerAssets}&useSmartTextTransfer=${useSmartTextTransfer}&apiKey=${apiKey}&${getNonceQueryParam()}`;
};

export const getAdjustForSubstrateUrl = (
    sourceUrl: string | null,
    targetSubstrateColor: string,
    minimumContrastRatio: string | number,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetSubstrateColor) {
        return null;
    }

    const source = encodeURIComponent(getUrl(sourceUrl) ?? '');
    const targetSubstrate = encodeURIComponent(targetSubstrateColor);

    return `${host}/api/v3/adaptation:adjustForSubstrate?targetSubstrateColor=${targetSubstrate}&documentUrl=${source}&minimumContrastRatio=${minimumContrastRatio}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
};

export const getAdjustForSurfaceUrl = (
    source: string | null,
    surfaceSpecificationUrl: string,
    targetSubstrateColor: string,
    apiKey: string,
): string | null => {
    if (!source || !surfaceSpecificationUrl) {
        return null;
    }

    const sourceUrl = encodeURIComponent(getUrl(source) ?? '');
    surfaceSpecificationUrl = encodeURIComponent(surfaceSpecificationUrl);

    if (targetSubstrateColor) {
        const substrateColor = encodeURIComponent(targetSubstrateColor);
        return `${host}/api/v3/adaptation:adjustForSurface?documentUrl=${sourceUrl}&surfaceSpecificationUrl=${surfaceSpecificationUrl}&targetSubstrateColor=${substrateColor}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
    }

    return `${host}/api/v3/adaptation:adjustForSurface?documentUrl=${sourceUrl}&surfaceSpecificationUrl=${surfaceSpecificationUrl}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
};

export const getAdjustForDecorationTechnologyUrl = (
    sourceUrl: string | null,
    targetDecorationTechnology: string,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetDecorationTechnology) {
        return null;
    }

    const source = encodeURIComponent(getUrl(sourceUrl) ?? '');
    return `${host}/api/v3/adaptation:adjustForDecorationTechnology?documentUrl=${source}&targetDecorationTechnology=${targetDecorationTechnology}&${getNonceQueryParam()}&apiKey=${apiKey}`;
};

export const getImproveArtworkContrastUrl = (
    sourceUrl: string | null,
    minimumContrastRatio: number | string | null,
    apiKey: string,
): string | null => {
    if (!sourceUrl) {
        return null;
    }

    const source = encodeURIComponent(getUrl(sourceUrl) ?? '');

    return `${host}/api/v3/quality:improveContrast?documentUrl=${source}&minimumContrastRatio=${minimumContrastRatio}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
};

export const getTransferStyleUrl = (
    sourceUrl: string | null,
    targetUrl: string | null,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetUrl) {
        return null;
    }

    const source = encodeURIComponent(getUrl(sourceUrl) ?? '');
    const target = encodeURIComponent(getUrl(targetUrl) ?? '');

    return `${host}/api/v3/preservation:transferStyle?sourceDocumentUrl=${source}&targetDocumentUrl=${target}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
};

export function getResizeDocumentWithSurfaceUrl(
    documentUrl: string,
    surfaceSpecificationUrl: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
): string {
    const url =
        `${host}/api/${version}/adaptation:resize?documentUrl=${encodeURIComponent(
            documentUrl,
        )}&surfaceSpecificationUrl=${encodeURIComponent(surfaceSpecificationUrl)}${
            minimumFontSize ? '&minimumFontSize=' + minimumFontSize : ''
        }&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}` +
        (version === 'v3beta' ? `&priority=${encodeURIComponent(priority)}` : '');

    return url;
}

export function getResizeDocumentWithSurfaceJson(
    documentUrl: string,
    surfaceSpecification: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
): string {
    const url =
        `${host}/api/${version}/adaptation:resize?documentUrl=${encodeURIComponent(
            documentUrl,
        )}&surfaceSpecification=${encodeURIComponent(JSON.stringify(surfaceSpecification))}${
            minimumFontSize ? '&minimumFontSize=' + minimumFontSize : ''
        }&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}` +
        (version === 'v3beta' ? `&priority=${encodeURIComponent(priority)}` : '');

    return url;
}

export function getFontSwapUrl(
    documentUrl: string | undefined,
    sourceFont: string | undefined,
    targetFont: string | undefined,
    apiKey: string,
): string | null {
    if (!documentUrl || !sourceFont || !targetFont) {
        return null;
    }

    const sourceDocumentParam = encodeURIComponent(getUrl(documentUrl) ?? '');
    const sourceFontParam = encodeURIComponent(sourceFont);
    const targetFontParam = encodeURIComponent(targetFont);

    return `${host}/api/v3/composition:swapFont?documentUrl=${sourceDocumentParam}&sourceFont=${sourceFontParam}&targetFont=${targetFontParam}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}`;
}

export const getComposeUrl = ({
    documentUrl,
    assets,
    apiKey,
    useSmartCompose,
}: {
    documentUrl: string | null;
    assets: ComposeAssets | null;
    apiKey: string;
    useSmartCompose: boolean;
}): string | null => {
    if (!documentUrl) {
        return null;
    }
    const data = encodeURIComponent(JSON.stringify(assets));
    const sourceDocumentUrl = encodeURIComponent(getUrl(documentUrl) ?? '');

    return `${host}/api/v3/composition:compose?documentUrl=${sourceDocumentUrl}&assets=${data}&requester=${REQUESTER}&${getNonceQueryParam()}&apiKey=${apiKey}&useSmartCompose=${useSmartCompose}`;
};

export const getCompositionGenerateUrl = ({
    assets,
    surfaceSpecificationUrl,
    surfaceSpecification,
    sourceDocumentUrl,
    productName,
    panels,
    dpiThreshold,
    apiKey,
    culture = 'en-us',
}: {
    assets?: GenerateAssets;
    surfaceSpecificationUrl?: string;
    surfaceSpecification?: any;
    sourceDocumentUrl?: string;
    productName?: string;
    panels?: string;
    dpiThreshold?: number;
    apiKey: string;
    culture?: string;
}): string => {
    const data = assets ? JSON.stringify(assets) : null;
    const waitTime = '30'; //We can wait longer, but always want to see the results in playground
    const baseUrl = new URL(`${host}/api/v3/composition:generate`);

    if (surfaceSpecificationUrl) baseUrl.searchParams.append('surfaceSpecificationUrl', surfaceSpecificationUrl);
    if (surfaceSpecification) baseUrl.searchParams.append('surfaceSpecification', JSON.stringify(surfaceSpecification));
    baseUrl.searchParams.append('productName', productName || '');
    baseUrl.searchParams.append('culture', culture);

    if (panels) {
        baseUrl.searchParams.append('panels', panels);
    }

    if (dpiThreshold) {
        baseUrl.searchParams.append('dpiThreshold', dpiThreshold.toString());
    }

    baseUrl.searchParams.append('apiKey', apiKey);
    baseUrl.searchParams.append('waitTime', waitTime);
    baseUrl.searchParams.append('nonce', uuidv4());

    if (sourceDocumentUrl) {
        baseUrl.searchParams.append('sourceDocumentUrl', sourceDocumentUrl);
    } else if (data) {
        baseUrl.searchParams.append('assets', data);
    }

    return baseUrl.href;
};

export const getGenerateAllLayoutUrls = async (
    assets: GenerateAssets | null,
    surfaceSpecificationUrl: string,
    productName: string | undefined,
    apiKey: string,
    signal?: AbortSignal,
): Promise<string[] | null> => {
    const data = encodeURIComponent(JSON.stringify(assets));
    const url = `${host}/api/v0/composition:generate?assets=
        ${data}&surfaceSpecificationUrl=${surfaceSpecificationUrl}&productName=${productName}&apiKey=${apiKey}&${getNonceQueryParam()}`;
    const response = await axios.get(url, { signal });
    const resultDocuments = JSON.parse(JSON.stringify(response));

    const resultDocuemtUrls = await Promise.all(
        resultDocuments.data.map(async (document: any) => {
            return await uploadJsonDocumentAndGetDocumentUrl(JSON.stringify(document), signal);
        }),
    );
    return resultDocuemtUrls;
};

export const getInspirationGenerateUrls = async ({
    themeQuery,
    iconCollection,
    texts,
    imageAsset,
    substrateColor,
    surfaceSpecificationUrl,
    surfaceSpecification,
    useImage,
    colorPalette,
    contentAlignment,
    signal,
    apiKey,
}: {
    themeQuery: string | null;
    iconCollection: string | null;
    texts: any | null;
    imageAsset?: any | null;
    surfaceSpecificationUrl: string | null;
    surfaceSpecification: any | null;
    substrateColor: string | null;
    useImage: boolean | null;
    colorPalette: any | null;
    contentAlignment?: string;
    signal?: AbortSignal;
    apiKey: string;
}): Promise<string[]> => {
    const url = new URL(`${host}/api/v3/inspiration:generate`);
    if (surfaceSpecificationUrl) url.searchParams.append('surfaceSpecificationUrl', surfaceSpecificationUrl);
    if (surfaceSpecification) url.searchParams.append('surfaceSpecification', JSON.stringify(surfaceSpecification));
    url.searchParams.append('apiKey', apiKey);
    url.searchParams.append('nonce', uuidv4());

    if (texts) url.searchParams.append('texts', JSON.stringify(texts));
    if (imageAsset) url.searchParams.append('imageAsset', imageAsset);
    if (substrateColor) url.searchParams.append('substrateColor', substrateColor);
    if (useImage != null) url.searchParams.append('useImage', `${useImage}`);
    if (colorPalette) url.searchParams.append('colorPalette', JSON.stringify(colorPalette));
    if (themeQuery) url.searchParams.append('themeQuery', themeQuery);
    if (iconCollection || contentAlignment) {
        const generateOptionsObj: InspirationGenerateOptions = {};
        if (iconCollection) generateOptionsObj.iconCollections = [iconCollection];
        if (contentAlignment) generateOptionsObj.contentAlignment = contentAlignment;
        url.searchParams.append('generateOptions', JSON.stringify(generateOptionsObj));
    }

    const response = await axios.get(url.href, { signal });

    const resultDocuemtUrls = await Promise.all(
        response.data.map(async (document: any) => {
            try {
                const url = getTransientDocumentUrl(document);
                return url;
            } catch (error) {
                document.deleteAfterDays = 1;
                return await uploadJsonDocumentAndGetDocumentUrl(document, signal);
            }
        }),
    );
    return resultDocuemtUrls;
};

export const getInspirationGenerateFromImageUrls = async ({
    themeQuery,
    imageUrl,
    iconCollection,
    texts,
    substrateColor,
    surfaceSpecificationUrl,
    surfaceSpecification,
    useImage,
    colorPalette,
    contentAlignment,
    signal,
    apiKey,
}: {
    themeQuery: string | null;
    imageUrl: string | null;
    iconCollection: string | null;
    texts: any | null;
    surfaceSpecificationUrl: string | null;
    surfaceSpecification: any | null;
    substrateColor: string | null;
    useImage: boolean | null;
    colorPalette: any | null;
    contentAlignment?: string;
    signal?: AbortSignal;
    apiKey: string;
}): Promise<string[]> => {
    const url = new URL(`${host}/api/v3/experiment:inspiration:generate`);
    if (surfaceSpecificationUrl) url.searchParams.append('surfaceSpecificationUrl', surfaceSpecificationUrl);
    if (surfaceSpecification) url.searchParams.append('surfaceSpecification', JSON.stringify(surfaceSpecification));
    url.searchParams.append('apiKey', apiKey);
    url.searchParams.append('nonce', uuidv4());

    if (texts) url.searchParams.append('texts', JSON.stringify(texts));
    if (substrateColor) url.searchParams.append('substrateColor', substrateColor);
    if (useImage != null) url.searchParams.append('useImage', `${useImage}`);
    if (colorPalette) url.searchParams.append('colorPalette', JSON.stringify(colorPalette));
    if (themeQuery) url.searchParams.append('themeQuery', themeQuery);
    if (imageUrl) {
        const imageAsset = {
            printUrl: imageUrl,
            previewUrl: imageUrl,
            originalSourceUrl: imageUrl,
        };
        url.searchParams.append('imageAsset', JSON.stringify(imageAsset));
    }
    if (iconCollection || contentAlignment) {
        const generateOptionsObj: InspirationGenerateOptions = {};
        if (iconCollection) generateOptionsObj.iconCollections = [iconCollection];
        if (contentAlignment) generateOptionsObj.contentAlignment = contentAlignment;
        url.searchParams.append('generateOptions', JSON.stringify(generateOptionsObj));
    }

    const response = await axios.get(url.href, { signal });

    const resultDocuemtUrls = await Promise.all(
        response.data.map(async (document: any) => {
            try {
                const url = getTransientDocumentUrl(document);
                return url;
            } catch (error) {
                document.deleteAfterDays = 1;
                return await uploadJsonDocumentAndGetDocumentUrl(document, signal);
            }
        }),
    );
    return resultDocuemtUrls;
};

function getTransientDocumentUrl(cimdoc: CimpressDocument) {
    const MAX_DATA_URI_LENGTH = 7000;

    const deflatedDocument = pako.deflateRaw(JSON.stringify(cimdoc), {
        to: 'string',
    } as any); // For some reason we're passing an object that doesn't match the types

    const transientDocument = base64js.fromByteArray(deflatedDocument);

    if (transientDocument.length > MAX_DATA_URI_LENGTH) {
        throw new Error(`Provided cimdoc exceeds inline document url length of ${MAX_DATA_URI_LENGTH}`);
    }

    return `https://storage.documents.cimpress.io/v3/documents/transient?document=${encodeURIComponent(
        transientDocument,
    )}`;
}

export const getRenderingUrlDocument = (documentUrl: string, panel: number, backgroundColor?: string): string => {
    const instructionsUri = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodeURIComponent(
        documentUrl,
    )}&surfaceOrdinals=${panel + 1}&ignoreProjection=True`;

    let renderingUrl = `https://rendering.documents.cimpress.io/v2/orchestration/preview?width=700&height=700&instructions_uri=${encodeURIComponent(
        instructionsUri,
    )}&format=png`;

    if (backgroundColor) {
        renderingUrl += `&bgColor=${backgroundColor}`;
    }

    return renderingUrl;
};

export const getRenderingUrl = (
    documentUrl: string,
    panel: number,
    surfaceSpecUrl: string,
    projection: string,
    backgroundColor: string | undefined,
): string => {
    const isSurfaceUrlDSS = surfaceSpecUrl.includes('design-specifications.design');

    const sceneUriPart = isSurfaceUrlDSS ? '' : createSceneUriPart(panel, projection, surfaceSpecUrl);
    const instructionsUriPart = createInstructionUriPart(documentUrl);

    let renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?${instructionsUriPart}&showerr=1&scene=${sceneUriPart}&width=1360&format=png`;
    if (backgroundColor) {
        renderingUrl += `&bgColor=${backgroundColor}`;
    }
    return renderingUrl;
};

export const getRenderingUrlWithPhotoScene = (documentUrl: string, scene: string | null): string => {
    const instructionsUriPart = createInstructionUriPart(documentUrl);
    const renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?${instructionsUriPart}&showerr=1&scene=${scene}&width=1360&format=png`;

    return renderingUrl;
};

function createInstructionUriPart(documentUrl: string): string {
    const docInstructionsUrl = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodeURIComponent(
        documentUrl,
    )}&salt=${Math.random()}`;

    const instructionsUriPart = docInstructionsUrl ? `instructions_uri=${encodeURIComponent(docInstructionsUrl)}` : '';

    return instructionsUriPart;
}

function createSceneUriPart(panel: number, projection: string, surfaceSpecUrl: string): string {
    const sceneJson = {
        width: 1000,
        page: panel + 1,
        projection: projection,
        // showFullBleed: true,
        product: {
            calculatedSurfaceSetUrl: surfaceSpecUrl,
        },
        layers: [
            {
                type: 'overlay',
                source: 'safe',
                stroke: {
                    color: 'rgb(0,0,0)',
                    dotted: true,
                },
            },
        ],
    };

    // Deflate the scene
    const deflatedScene = pako.deflateRaw(JSON.stringify(sceneJson), {
        to: 'string',
    } as any); // For some reason we're passing an object that doesn't match the types

    const transientScene = base64js.fromByteArray(deflatedScene);

    // Construct the scene Url
    const sceneUrl = `https://scenes.documents.cimpress.io/v3/transient?data=${encodeURIComponent(transientScene)}`;
    const sceneUriPart = `${encodeURIComponent(sceneUrl)}`;

    return sceneUriPart;
}

export function isUrlAllowed(url: string, allowedDomains = ['artworkgeneration.cimpress.io']): boolean {
    try {
        const parsedUrl = new URL(url);
        return allowedDomains.some((domain) => parsedUrl.hostname.includes(domain));
    } catch (error) {
        return false;
    }
}

export const getImageFromTextUrls = async ({
    prompt,
    modelSelectedOption,
    contentTypeSelectedOption,
    aspectRatioSelectedOption,
    imageCountSelectedOption,
    signal,
    apiKey,
}: {
    prompt: string;
    modelSelectedOption: string;
    contentTypeSelectedOption: string;
    aspectRatioSelectedOption: string;
    imageCountSelectedOption: string;
    signal: AbortSignal;
    apiKey: string;
}): Promise<string[]> => {
    const url = new URL(`${host}/api/v3beta/inspiration:generateImageFromText`);
    const params = {
        prompt,
        imageCount: imageCountSelectedOption,
        aspectRatio: aspectRatioSelectedOption,
        model: modelSelectedOption,
        contentType: contentTypeSelectedOption,
        apiKey,
    };

    Object.entries(params).forEach(([key, value]) => {
        if (value) url.searchParams.append(key, value.toString());
    });

    try {
        const response = await fetch(url.toString(), {
            method: 'GET',
            signal,
        });

        if (!response.ok) {
            throw new Error(`Error fetching images: ${response.statusText}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error in fetching image URLs:', error);
        throw error;
    }
};
