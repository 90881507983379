import { Button, Card, Checkbox, Select, Spinner, TextField, Tooltip } from '@cimpress/react-components';
import { useContext, useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { getInspirationGenerateUrls, getPhotoSceneUrl } from '../api';
import { ApiKeyContext } from '../App';
import {
    CardBuilder,
    Content,
    Control,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    SurfaceSelector,
    type SelectorOption,
} from '../Components';
import { ImageAssetControl } from '../Components/InspirationGenerate/ImageAssetControl';
import { useInspirationGenerateParams } from '../Components/InspirationGenerate/useInspirationGenerateParams';
import { NONE_SELECTOR_OPTION } from '../constants';
import contentAlignmentOptions from '../data/contentAlignmentOptions.json';
import presetSurfaces from '../data/generateSurfaceJson.json';
import presetSurfaceUrls from '../data/generateSurfaceUrls.json';
import { useCancelableEffect } from '../tools'; // Removed useDebounce import
import { isSmallScreen } from '../tools/isSmallScreen';

const colorPaletteOptions = [
    NONE_SELECTOR_OPTION,
    {
        label: 'Color Palette 1',
        value: `{
            "primary": "#26c799",
            "secondary": "#ffaa00"
        }`,
    },
    {
        label: 'Color Palette 2',
        value: `{
            "primary": "#7ED321",
            "secondary": "#F5A623"
        }`,
    },
    {
        label: 'Color Palette 3',
        value: `{
            "primary": "#D0021B",
            "secondary": "#000000"
        }`,
    },
];

export const InspirationGeneratePage = () => {
    const {
        primaryText,
        secondaryText,
        imageAsset,
        substrateColor,
        textOnly,
        surfaceSpecificationUrl,
        surfaceSpecification,
        themeQuery,
        colorPaletteSelectedOption,
        iconCollection,
        contentAlignment: contentAlignment,
        setColorPaletteSelectedOption,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
        setThemeQuery,
        setTextOnly,
        setSubstrateColor,
        setPrimaryText,
        setSecondaryText,
        setImageAsset,
        setContentAlignment: setContentAlignment,
    } = useInspirationGenerateParams();
    const [resultRow, setResultRow] = useState(PreviewRow());
    const [isLoading, setIsLoading] = useState(false);
    const [callGenerate, setCallGenerate] = useState(false);
    const apiKey = useContext(ApiKeyContext);

    useEffect(() => {
        setCallGenerate(true);
    }, []);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (
                    (surfaceSpecificationUrl || surfaceSpecification) &&
                    (primaryText || secondaryText || !textOnly) &&
                    callGenerate
                ) {
                    setIsLoading(true);

                    try {
                        const texts = primaryText
                            ? [
                                  {
                                      content: primaryText,
                                      type: 'primary',
                                      isPlaceholder: false,
                                  },
                              ]
                            : [];

                        if (secondaryText)
                            texts.push({ content: secondaryText, type: 'secondary', isPlaceholder: false });

                        const colorPalette = colorPaletteSelectedOption?.value
                            ? JSON.parse(colorPaletteSelectedOption.value)
                            : null;

                        const resultDocumentUrls = await getInspirationGenerateUrls({
                            themeQuery,
                            iconCollection,
                            texts,
                            imageAsset: !textOnly && imageAsset ? imageAsset : '',
                            surfaceSpecificationUrl,
                            surfaceSpecification,
                            substrateColor,
                            useImage: !textOnly,
                            colorPalette,
                            contentAlignment: contentAlignment.value,
                            signal,
                            apiKey,
                        });

                        const photoSceneUrl = surfaceSpecificationUrl
                            ? ((await getPhotoSceneUrl(surfaceSpecificationUrl)) ?? '')
                            : undefined;

                        const resultCards = resultDocumentUrls?.map((documentUrl, i) =>
                            CardBuilder.ofType('documentUrl')
                                .withTitle(`Result Document ${i + 1}`)
                                .withData({
                                    documentUrl,
                                    surfaceUrl: surfaceSpecificationUrl ?? undefined,
                                    photoSceneUrl,
                                    backgroundColor: substrateColor?.substring(1) ?? 'FFFFFF',
                                })
                                .build(),
                        );

                        setResultRow(PreviewRow(...resultCards));
                    } catch (error) {
                        if (signal.aborted) {
                            return;
                        }

                        const resultCard = CardBuilder.ofType('documentUrl')
                            .withTitle('Result Document')
                            .withLoader(async () => {
                                throw error;
                            })
                            .build();
                        setResultRow(PreviewRow(resultCard));
                    } finally {
                        setIsLoading(false);
                        setCallGenerate(false);
                    }
                }
            })();
        },
        [
            themeQuery,
            primaryText,
            secondaryText,
            imageAsset,
            surfaceSpecificationUrl,
            substrateColor,
            colorPaletteSelectedOption,
            textOnly,
            iconCollection,
            surfaceSpecification,
            contentAlignment,
            apiKey,
            callGenerate,
        ],
    );

    const onColorPaletteChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setColorPaletteSelectedOption(selected);
    };

    function onClickGenerate() {
        setCallGenerate(true);
    }

    return (
        <Page>
            <Page.Title>Generate</Page.Title>

            <Page.Controls>
                <Control>
                    <Button onClick={onClickGenerate}>Generate</Button>
                    <Control.Title>Theme Query</Control.Title>
                    <TextField
                        label="Theme Query"
                        onChange={(e) => setThemeQuery(e.target.value)}
                        value={themeQuery ?? ''}
                    />
                </Control>

                <Control spacing="compact">
                    <Control.Title>Texts</Control.Title>

                    <TextField
                        label="Primary Text"
                        onChange={(event) => setPrimaryText(event.target.value)}
                        value={primaryText ?? ''}
                    />

                    <TextField
                        label="Secondary Text"
                        onChange={(event) => setSecondaryText(event.target.value)}
                        value={secondaryText ?? ''}
                    />

                    <Card>
                        <Checkbox
                            label={
                                <Tooltip
                                    direction={isSmallScreen() ? 'bottom' : 'right'}
                                    contents="When checked, result documents will contain only text and shapes, but no images"
                                >
                                    <div>Text Only</div>
                                </Tooltip>
                            }
                            checked={textOnly}
                            onChange={(event) => {
                                setTextOnly(event.target.checked);
                                setCallGenerate(true);
                            }}
                        />
                    </Card>
                </Control>

                <ImageAssetControl
                    initialImageAsset={imageAsset ? JSON.parse(imageAsset) : null}
                    setImageAsset={setImageAsset}
                />

                <Control>
                    <Control.Title>Color Palette</Control.Title>
                    <Select
                        onChange={onColorPaletteChange}
                        value={colorPaletteSelectedOption}
                        options={colorPaletteOptions}
                        label="Color Palette"
                    />
                </Control>

                <Control>
                    <Control.Title>Surface Selection</Control.Title>
                    <SurfaceSelector
                        surfaceSpecificationUrl={surfaceSpecificationUrl ?? ''}
                        setSurfaceSpecificationUrl={setSurfaceSpecificationUrl}
                        surfaceSpecification={surfaceSpecification}
                        setSurfaceSpecification={setSurfaceSpecification}
                        presetSurfaceUrlsFromPage={presetSurfaceUrls}
                        presetSurfaceJsonFromPage={presetSurfaces}
                    />
                </Control>

                <Control>
                    <Control.Title>Content Alignment</Control.Title>
                    <Select
                        onChange={setContentAlignment}
                        value={contentAlignment}
                        options={contentAlignmentOptions}
                        label="Content Alignment (Vertical)"
                        isSearchable={false}
                    />
                </Control>

                <Control>
                    <Control.Title>Substrate Color</Control.Title>
                    <SketchPicker
                        width="65%"
                        color={substrateColor ?? '#FFFFFF'}
                        onChangeComplete={(e) => setSubstrateColor(e.hex)}
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                {isLoading ? (
                    <Content.Card sticky size="large">
                        <Content.Section>
                            <Spinner />
                        </Content.Section>
                    </Content.Card>
                ) : (
                    <PreviewSection
                        introduction={
                            'This is a page to test the inspiration generate endpoint. Fill in the assets and surface URL to see generated results.'
                        }
                        showIntro={!(surfaceSpecificationUrl && (primaryText || secondaryText || !textOnly))}
                        rows={PreviewRows(resultRow)}
                    />
                )}
            </Page.Content>
        </Page>
    );
};
